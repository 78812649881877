<template>
  <ValidationObserver>
    <form @submit.prevent="saveData">
      <b-card title="Update Vendor">
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Nama Vendor</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.vendor_name"
              placeholder="Nama vendor"
              required
            />
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Category Vendor</label>
          <div class="col">
            <v-select
              v-model="formPayload.id_vendor_category"
              placeholder="-- Kategori vendor --"
              label="vendor_category_name"
              :options="category"
              :reduce="(category) => category.id"
            />
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">No Hp</label>
          <div class="col">
            <b-form-input
              v-model="formPayload.vendor_phone"
              type="number"
              placeholder="No Hp"
              required
            />
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Vendor Image</label>
          <div class="col">
            <div v-if="formPayload.vendor_imageurl" class="mt-1 relative">
              <img
                :src="vendorImage"
                height="200"
                width="200"
              />
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle"
                @click="cancelPhoto()"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
            <div v-else>
              <b-form-file
                id="files"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="onFileSelectedVendorImage"
                accept="image/png, image/gif, image/jpeg"
              />
            </div>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Alamat vendor</label>
          <div class="col">
            <b-form-textarea
              v-model="formPayload.address"
              placeholder="Alamat vendor"
              rows="3"
              required
            />
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Provinsi</label>
          <div class="col">
            <v-select
              v-model="formPayload.province"
              placeholder="-- Provinsi --"
              label="name"
              :options="listProvince"
              :reduce="(listProvince) => listProvince.id"
            />
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Kota</label>
          <div class="col">
            <v-select
              v-model="formPayload.city"
              placeholder="-- Kota --"
              label="name"
              :options="listCity"
              :reduce="(listCity) => listCity.id"
            />
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">KTP</label>
          <div class="col">
            <div v-if="formPayload.identity_card_file" class="mt-1 relative">
              <a :href="formPayload.identity_card_file" target="_blank" data-toggle="tooltip" title="Detail">
                <img src="@/assets/images/icons/pdf.png" />
              </a>
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle ml-2"
                @click="cancelIdentityCard()"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
            <div v-else>
              <b-form-file
                id="files"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="onIdentityCardFileSelected"
                accept="image/png, image/gif, image/jpeg, application/pdf"
              />
            </div>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">CV</label>
          <div class="col">
            <div v-if="formPayload.cv_file" class="mt-1 relative">
              <a :href="formPayload.cv_file" target="_blank" data-toggle="tooltip" title="Detail">
                <img src="@/assets/images/icons/pdf.png" />
              </a>
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle ml-2"
                @click="cancelFileCv()"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
            <div v-else>
              <b-form-file
                id="files"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="onFileSelectedCvFile"
                accept="application/pdf"
              />
            </div>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label" for="files">SIUP</label>
          <div class="col">
            <div v-if="formPayload.siup_file" class="mt-1 relative">
              <a :href="formPayload.siup_file" target="_blank" data-toggle="tooltip" title="Detail">
              <img src="@/assets/images/icons/pdf.png" />
              </a>
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle ml-2"
                @click="cancelFileSiup()"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
            <div v-else>
              <b-form-file
                id="files"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="onFileSelectedSiupFile"
                accept="application/pdf"
              />
            </div>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label" for="files">TDP</label>
          <div class="col">
            <div v-if="formPayload.tdp_file" class="mt-1 relative">
              <a :href="formPayload.tdp_file" target="_blank" data-toggle="tooltip" title="Detail">
              <img src="@/assets/images/icons/pdf.png" />
              </a>
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle ml-2"
                @click="cancelFileTdp()"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
            <div v-else>
              <b-form-file
                id="files"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="onFileSelectedTdpFile"
                accept="application/pdf"
              />
            </div>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label" for="files"
            >Certificate file</label
          >
          <div class="col">
            <div v-if="formPayload.certificate_file" class="mt-1 relative">
              <a :href="formPayload.certificate_file" target="_blank" data-toggle="tooltip" title="Detail">
              <img src="@/assets/images/icons/pdf.png" />
              </a>
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle ml-2"
                @click="cancelFileTdp()"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
            <div v-else>
              <b-form-file
                id="files"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="onFileSelectedCertificateFile"
                accept="application/pdf"
              />
            </div>
          </div>
        </div>
      </b-card>
      <b-col cols="12" class="p-0 mt-2">
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Save
        </button>
        &nbsp; &nbsp;
        <a
          @click="handleBackButton()"
          variant="secondary"
          class="btn waves-effect waves-float waves-light btn-secondary"
        >
          Cancel
        </a>
      </b-col>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormFile,
  BButton,
  BSpinner,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { VueEditor } from "vue2-editor";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormFile,
    BFormInput,
    BFormTextarea,
    VueEditor,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      isLoading: false,
      formPayload: {
        vendor_imageurl: "",
      },
      fileVendorImage: [],
      vendorImage: "",
      filesUrl: [],
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic"],
        ["image", "video"],
        ["link"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      snowOption: {
        theme: "snow",
      },
      category: [],
      subCategory: [],
      validations: "",
      category: [],
      listProvince: [],
      listCity: [],
    };
  },
  setup(props) {
    return {
      successNotification,
      errorNotification,
    };
  },

  watch: {
    "formPayload.province": function(data) {
      this.getCity(data);
    },
  },

  created() {
    this.loadData();
    this.categoryName();
    this.getProvince();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    inputSubCategory(slug) {
      this.$http
        .get(`/admin/sub-category-contents?content_category_slug=${slug}`)
        .then((response) => {
          this.subCategory = response.data.data;
        });
    },
    categoryName() {
      this.$http.get("/admin/vendor-category").then((response) => {
        this.category = response.data.data;
      });
    },
    getProvince() {
      this.$http.get("/admin/util/province/1").then((response) => {
        this.listProvince = response.data.data;
      });
    },
    getCity(data) {
      this.$http.get(`/admin/util/city/${data}`).then((response) => {
        this.listCity = response.data.data;
      });
    },
    async subCategoryName() {
      await this.$http.get("/admin/sub-category-contents").then((response) => {
        this.subCategory = response.data.data;
      });
    },
    cancelPhoto() {
      this.formPayload.vendor_imageurl = null;
    },
    onFileSelectedVendorImage(event) {
      this.formPayload.vendor_imageurl = event.target.files[0];
      this.showfileVendorImage(event.target.files[0]);
    },
    showfileVendorImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.vendorImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileSelectedCvFile(event) {
      this.formPayload.cv_file = event.target.files[0];
      this.showfileCv(event.target.files[0]);
    },
    onIdentityCardFileSelected(event) {
      this.formPayload.identity_card_file = event.target.files[0];
      this.showIdentityCardFile(event.target.files[0]);
    },
    showIdentityCardFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.formPayload.identity_card_file = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    showfileCv(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.formPayload.cv_file = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    cancelIdentityCard() {
      this.formPayload.identity_card_file = null;
    },
    cancelFileCv() {
      this.formPayload.cv_file = null;
    },
    onFileSelectedSiupFile(event) {
      this.formPayload.siup_file = event.target.files[0];
      this.showfileSiup(event.target.files[0]);
    },
    showfileSiup(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.formPayload.siup_file = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    cancelFileSiup() {
      this.formPayload.siup_file = null;
    },
    onFileSelectedTdpFile(event) {
      this.formPayload.tdp_file = event.target.files[0];
      this.showfileTdp(event.target.files[0]);
    },
    showfileTdp(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.formPayload.tdp_file = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    cancelFileTdp() {
      this.formPayload.tdp_file = null;
    },
    onFileSelectedCertificateFile(event) {
      this.formPayload.certificate_file = event.target.files[0];
      this.showfileCertificate(event.target.files[0]);
    },
    showfileCertificate(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.formPayload.certificate_file = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    cancelFileTdp() {
      this.formPayload.certificate_file = null;
    },
    loadData() {
      const params = this.$route.params.slug;
      this.$http
        .get("/admin/vendor/" + params)
        .then((response) => {
          this.formPayload = response.data.data;
          this.formPayload.id_vendor_category = this.formPayload.category.id;
          this.formPayload.province = this.formPayload.province.id;
          this.formPayload.city = this.formPayload.city.id;
          this.vendorImage = this.formPayload.vendor_imageurl;
        })
        .then(() => {
          this.categoryName();
          this.subCategoryName();
        })
        .catch((error) => {
        });
    },

    preparePayload() {
      const form = new FormData();
      if (
        this.formPayload.category !== null &&
        this.formPayload.subCategory !== null
      ) {
        this.formPayload.id_category_content = this.formPayload.category
          ? this.formPayload.category.id
          : "";
        this.formPayload.id_subcategory_content = this.formPayload.sub_category
          ? this.formPayload.sub_category.id
          : "";
      }
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key)) {
          if (
            key != "thumbnail" &&
            key != "files" &&
            key != "sub_category" &&
            key != "category"
          ) {
            form.append(key, this.formPayload[key]);
          } else {
            if (
              typeof this.formPayload.thumbnail != "string" &&
              this.formPayload.thumbnail != null
            ) {
              form.append("thumbnail", this.formPayload["thumbnail"]);
            }
          }
        }
      }
      if (this.formPayload.files) {
        for (let index = 0; index < this.formPayload.files.length; index++) {
          const element = this.formPayload.files[index];
          if (typeof element.content != "string") {
            form.append("files[]", element);
          }
        }
      }
      return form;
    },

    saveData() {
      const slugs = this.$route.params.slug;
      var form = this.preparePayload();
      this.isLoading = true;
      form.append("_method", "PATCH");
      this.$http
        .post(`/admin/vendor/${slugs}`, form, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          successNotification(this, "Success", "Vendor Updated!");
          this.$router.push({ name: "vendor-list" });
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, "Oops!", error.response.data.meta.messages);
          }
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
            errorNotification(this, "Nama", this.validations.name.join(" "));
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
